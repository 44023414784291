import { ref, watch } from "vue";

import {
  getBookInfo as _getBookInfo,
  DocBookInfo,
  BookType,
  DocChatInfo,
  getPostRecords as _getPostRecords,
  createBook as _createBook,
  updateBook as _updateBook,
  deleteBook as _deleteBook,
} from "@/api/doc";

const activeNav = ref<BookType>(BookType.init);
import { message } from "ant-design-vue";
const searchVal = ref("");

const bookList = ref<Array<DocBookInfo>>([]);
const historyList = ref<Array<DocChatInfo>>([]);

const currentTab = ref<Array<"open" | "edit">>(["open"]);

let current = 1;
const pageSize = 30;

const historyLoading = ref(false);
const loading = ref(false);

/**
 *
 * @param judge 是否重置数据
 * @description 获取知识页
 */
const getBookInfo = (judge?: unknown): void => {
  if (!(activeNav.value === BookType.system || activeNav.value === BookType.project))
    return;

  if (judge === true) {
    current = 1;
    bookList.value = [];
    isMore.value = true;
    loading.value = false;
  }

  const data: Global.RequestParam = {
    param: {
      filter: {
        type: activeNav.value,
      },
      page: [current, pageSize],
    },
  };

  if (searchVal.value.trim()) {
    data.param.filter.name = ["-lk", `%${searchVal.value}%`];
  }

  if (!isMore.value || loading.value) return;

  loading.value = true;

  _getBookInfo(data)
    .then((res) => {
      if (res && res.code === 0 && res.data) {
        current++;

        if (res.data.length !== pageSize) {
          isMore.value = false;
        }

        if (judge === true) {
          bookList.value = [...res.data];
        } else {
          bookList.value = [...bookList.value, ...res.data];
        }
      }
      loading.value = false;
    })
    .catch((error) => {
      if (error.message !== "cancel") {
        loading.value = false;
      }
    });
};

const isMore = ref(true);
const historyIsMore = ref(true);

type BookOperateData = {
  name: string;
  description: string;
  id: number;
};

const createBook = (val: BookOperateData, cb: (_: boolean) => void): void => {
  const { name, description } = val;

  const data = {
    data: {
      name,
      description,
      type: 2,
    },
  };
  _createBook(data)
    .then((res) => {
      if (res && res.code === 0 && res.data) {
        message.success("新增成功！");
        cb(true);
      } else {
        cb(false);
      }
    })
    .catch(() => {
      cb(false);
    });
};
const updateBook = (val: BookOperateData, cb: (_: boolean) => void): void => {
  const data = {
    data: val,
  };
  _updateBook(data)
    .then((res) => {
      if (res && res.code === 0 && res.data) {
        message.success("编辑成功！");
        cb(true);
      } else {
        cb(false);
      }
    })
    .catch(() => {
      cb(false);
    });
};
const deleteBook = (id: number): void => {
  const data = {
    param: {
      filter: {
        id,
      },
    },
  };
  _deleteBook(data).then((res) => {
    if (res && res.code === 0 && res.data) {
      getBookInfo(true);
      message.success("删除成功！");
    }
  });
};

/**
 *
 * @param judge 是否重置数据
 * @description 获取文件记录
 */
const getPostRecords = (judge?: unknown): void => {
  if (activeNav.value !== BookType.history) return;

  if (judge === true) {
    current = 1;
    historyList.value = [];
    historyIsMore.value = true;
    historyLoading.value = false;
  }

  const data: Global.RequestParam = {
    param: {
      filter: {
        title: searchVal.value,
        recently: currentTab.value[0],
      },
      page: [current, pageSize],
    },
  };

  if (!historyIsMore.value || historyLoading.value) return;

  historyLoading.value = true;
  _getPostRecords(data)
    .then((res) => {
      if (res && res.code === 0 && res.data) {
        current++;

        if (res.data.length !== pageSize) {
          historyIsMore.value = false;
        }

        if (judge === true) {
          historyList.value = [...res.data];
        } else {
          historyList.value = [...historyList.value, ...res.data];
        }
      }
      historyLoading.value = false;
    })
    .catch((error) => {
      if (error.message !== "cancel") {
        historyLoading.value = false;
      }
    });
};

watch(activeNav, () => {
  searchVal.value = "";
  getBookInfo(true);
  getPostRecords(true);
});
watch(currentTab, () => {
  getPostRecords(true);
});

export {
  bookList,
  activeNav,
  searchVal,
  getBookInfo,
  currentTab,
  getPostRecords,
  historyList,
  historyLoading,
  loading,
  createBook,
  updateBook,
  deleteBook,
};
