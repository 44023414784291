import { h, ref, Ref, watchEffect, defineComponent } from "vue";
import { Input } from "ant-design-vue";

export default defineComponent({
  props: {
    maxlength: {
      type: Number,
      default: 64,
    },
    defaultValue: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "请输入内容",
    },
  },
  emits: ["update:value"],
  setup(props, { emit, expose }) {
    const input = ref();

    const inputValue: Ref<string> = ref("");

    watchEffect(() => {
      inputValue.value = props.value;
    });
    watchEffect(() => {
      emit("update:value", inputValue.value);
    });

    const focus = () => {
      input.value.focus();
    };

    expose({
      focus,
    });

    return () =>
      h(
        Input,
        {
          ref: input,
          value: inputValue.value,
          "onUpdate:value": (value: string) => {
            inputValue.value = value;
          },
          maxlength: props.maxlength,
          placeholder: props.placeholder,
        },
        {
          suffix: h(
            "div",
            {
              class: "opacity6",
            },
            `${inputValue.value.length} / ${props.maxlength}`
          ),
        }
      );
  },
});
